import React, { useEffect, useRef, useState } from "react";
import { TextField, Button, Typography, Slide } from "@mui/material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Api from "../../services/httpservice";
import { useJsApiLoader } from "@react-google-maps/api";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddSite({ show = "false", refreshSites }) {
  const [showSite, setShowSite] = useState(show);

  const initialFormValues = {
    site: "",
    address: "",
    latitude: "",
    longitude: "",
  };

  const [values, setValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({});

  const [openLatLngConfirmation, setopenLatLngConfirmation] = useState(false);
  const [agreeSave, setAgreeSave] = useState(false);

  const placeInputRef = useRef(null);

  const isDarkMode = JSON.parse(localStorage.getItem("darkMode"));

  const handleInputValue = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    validate({ [name]: value });
  };

  function validate(fieldValues = values) {
    let temp = { ...errors };
    if ("site" in fieldValues)
      temp.site = fieldValues.site ? "" : "This field is required.";
    if ("address" in fieldValues)
      temp.address = fieldValues.address ? "" : "This field is required.";
    setErrors({ ...temp });
  }

  // const handleSuccess = () => { setValues({ ...initialFormValues, formSubmitted: true, success: true });  };
  // const handleError = () => { setValues({ ...initialFormValues, formSubmitted: true, success: false });   };

  const handleSuccess = () => {
    setValues(initialFormValues);
  };

  const handleError = () => {
    setErrors({});
  };

  const formIsValid = (fieldValues = values) => {
    const isValid =
      fieldValues.site &&
      fieldValues.address &&
      Object.values(errors).every((x) => x === "");
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    validate(values);
    const isValid =
      Object.values(errors).every((x) => x === "") && formIsValid();

    if (isValid) {
      if (values.latitude && values.longitude) {
        setopenLatLngConfirmation(false);
        saveSite();
      } else {
        setopenLatLngConfirmation(true);
      }
    }
  };

  const handleClose = () => {
    setValues(initialFormValues);
    setErrors({});
    refreshSites("select");
    setShowSite(false);
  };

  async function saveSite() {
    await Api.post("sitetechnical", values)
      .then((res) => {
        handleSuccess();
        handleError();
        setShowSite(false);
        refreshSites(values.site);
      })
      .catch((err) => {
        console.log(err);
        refreshSites("select");
      });
  }

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const initPlaceAPI = () => {
    let autocomplete = new window.google.maps.places.Autocomplete(
      placeInputRef.current,
      { componentRestrictions: { country: "nz" } }
    );

    //set automplete fields
    autocomplete.setFields(["formatted_address", "geometry"]);

    new window.google.maps.event.addListener(
      autocomplete,
      "place_changed",
      function () {
        let result = autocomplete.getPlace();
        setValues((currState) => ({
          ...currState,
          ["address"]: result.formatted_address,
          ["latitude"]: result.geometry.location.lat().toString().slice(0, 11),
          ["longitude"]: result.geometry.location.lng().toString().slice(0, 11),
        }));
      }
    );
  };

  useEffect(() => {
    setShowSite(show);
  }, [show]);

  useEffect(() => {
    if (agreeSave) {
      saveSite();
    }
  }, [agreeSave]);

  return (
    <>
      <Dialog
        open={showSite}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            backgroundColor: isDarkMode ? "lightgray" : "white",
            // color: isDarkMode ? "white" : "black",
          },
        }}
      >
        <form onSubmit={handleSubmit} className="p-10 set-z-index">
          <Typography variant="h6">Add New Site</Typography>
          <TextField
            sx={{ mt: 2, mb: 2 }}
            name="site"
            onBlur={handleInputValue}
            onChange={handleInputValue}
            label="site"
            fullWidth
            {...(errors["site"] && { error: true, helperText: errors["site"] })}
          />
          <TextField
            name="address"
            onFocus={initPlaceAPI}
            inputRef={placeInputRef}
            onBlur={handleInputValue}
            onChange={handleInputValue}
            label="address"
            fullWidth
            {...(errors["address"] && {
              error: true,
              helperText: errors["address"],
            })}
          />
          <Typography variant="caption" sx={{ mt: 2 }}>
            latitude : {values.latitude ? values.latitude : "N/A"}{" "}
          </Typography>
          <Typography variant="caption" sx={{ mt: 1 }}>
            longitude : {values.longitude ? values.longitude : "N/A"}
          </Typography>
          <DialogActions>
            <Button type="submit" variant="contained">
              Save
            </Button>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* confirmation pop up if no lat /lng provided */}
      <Dialog open={openLatLngConfirmation}>
        <DialogTitle>Google Maps API</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Unable to find location on Map. Site will be only displayed in List
            View and not in Map View. Do you wish to save?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setAgreeSave(true);
              setopenLatLngConfirmation(false);
            }}
          >
            Yes
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setAgreeSave(false);
              setopenLatLngConfirmation(false);
            }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
