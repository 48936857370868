import { TextField, Slide } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PlacesAutocomplete = (props) => {
  const placeInputRef = useRef(null);
  const [place, setPlace] = useState(props.props?.rowData);

  const [open, setOpen] = useState(false);

  const [onSelect, setOnSelect] = useState(false);

  useEffect(() => {
    initPlaceAPI();
  }, []);

  const NEW_ZEALAND_BOUNDS = {
    north: -34,
    south: -47,
    west: 166,
    east: -173,
  };

  const initPlaceAPI = () => {
    let autocomplete = new window.google.maps.places.Autocomplete(
      placeInputRef.current,
      { componentRestrictions: { country: "nz" } }
    );

    //set automplete fields
    autocomplete.setFields(["formatted_address", "geometry"]);

    new window.google.maps.event.addListener(
      autocomplete,
      "place_changed",
      function () {
        let result = autocomplete.getPlace();
        setOnSelect(true);
        setPlace((currState) => ({
          ...currState,
          ["address"]: result.formatted_address,
          ["latitude"]: result.geometry.location.lat().toString(),
          ["longitude"]: result.geometry.location.lng().toString(),
        }));
      }
    );
  };

  useEffect(() => {
    props.props.onRowDataChange(place);
  }, [place]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnBlur = (e) => {
    // check if null error
    // type and not selected - clear lat/lng  and message
    // no scenario of selected but no lat/lng

    let geocoder = new window.google.maps.Geocoder();
    let value = e.target.value;

    if (value) {
      geocoder.geocode(
        { address: value, bounds: NEW_ZEALAND_BOUNDS },
        function (results, status) {
          if (status === "OK") {
            setPlace((currState) => ({
              ...currState,
              ["address"]: results[0].formatted_address,
              ["latitude"]: results[[0]].geometry.location.lat().toString(),
              ["longitude"]: results[0].geometry.location.lng().toString(),
            }));
          } else {
            // alert(
            //   "Geocode was not successful for the following reason: " + status
            // );
            setPlace((currState) => ({
              ...currState,
              ["address"]: value,
              ["latitude"]: "",
              ["longitude"]: "",
            }));
            setOpen(true);
          }
        }
      );
    } else {
      setPlace((currState) => ({
        ...currState,
        ["address"]: value,
        ["latitude"]: "",
        ["longitude"]: "",
      }));
    }
  };

  return (
    <>
      <TextField
        inputRef={placeInputRef}
        sx={{ width: 300 }}
        error={!props.props.value}
        onBlur={handleOnBlur}
        placeholder="Address"
        defaultValue={place.address}
      />

      {/* confirmation pop up if no lat /lng provided */}
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogTitle>Google Places API : Longitude and Latitude</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {
              "Unable to load latitude and longitude. If user prefers to save, site will be only displayed in List View and not in Map View."
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PlacesAutocomplete;
