import React, { useState, useEffect, forwardRef } from "react";
import Api from "../services/httpservice";
import MaterialTable from "material-table";

import {
  ThemeProvider,
  createTheme,
  Box,
  Select,
  MenuItem,
  IconButton,
  TextField,
} from "@mui/material";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import ViewInArIcon from "@mui/icons-material/ViewInAr";

import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";

import AddSite from "./shared/AddSite";
import CommentDialog from "./shared/CommentDialog";

function SiteList() {
  const [siteData, setSiteData] = useState([]);
  const [siteList, setSiteList] = useState([]);
  const [commentRowData, setCommentRowData] = useState({});
  const defaultMaterialTheme = createTheme();

  const [open, setOpen] = useState(false);
  const [commentInfo, setCommentInfo] = useState([]);

  const [showSite, setShowSite] = useState(false);
  const [currSite, setCurrSite] = useState();

  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);

  const [loading, setLoading] = useState(false)

  const isDarkMode = JSON.parse(localStorage.getItem("darkMode"));

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const columns = [
    {
      title: "Site",
      field: "site",
      editable: "onAdd",
      validate: (rowData) => !!rowData.site,
      // editable: 'never'
      editComponent: (props) => (
        <>
          <div>
            <Select
              id="select-site"
              value={currSite}
              error={!currSite || currSite === "Select" || currSite === "add"}
              onChange={(e) => {
                let val = e.target.value;
                props.onChange(val);
                setCurrSite(val);
                if (val === "add") {
                  setShowSite(true);
                } else {
                  setShowSite(false);
                }
                setCurrSite(val);
              }}
              label="Add new Site"
              defaultValue="select"
            >
              <MenuItem
                value={"select"}
                key={"select"}
                style={{ color: "lightgray" }}
              >
                {"Select Site"}
              </MenuItem>
              <MenuItem value={"add"} key={"add"}>
                {"Add new Site"}
              </MenuItem>
              {siteList?.map((site) => (
                <MenuItem value={site.site} key={site.id}>
                  {site.site}
                </MenuItem>
              ))}
            </Select>
          </div>
        </>
      ),
    },
    {
      title: "Plant",
      field: "description",
      // width: "45%",
      validate: (rowData) => !!rowData.description,
    },
    {
      title: "Model",
      field: "modelId",
      render: (rowData) => (
        <div>
          {rowData.modelId ? (
            <>
              <a
                className="model-url-list"
                href={process.env.REACT_APP_MATTERPORT_URL + rowData.modelId}
                target="_blank"
                rel="noopener noreferrer"
              >
                <ViewInArIcon />
              </a>
            </>
          ) : (
            <>
              <span className="model-unavail-url"> N/A </span>
            </>
          )}
        </div>
      ),
    },
    {
      title: "Status",
      field: "requestStatus",
      validate: (rowData) => !!rowData.requestStatus,
    },
    {
      title: "Model Plan Date",
      field: "modelPlannedDate",
      // editable: "never",
      type: "date",
      dateSetting: { locale: "en-NZ", format: "dd/MM/yyyy" },
      //validate: (rowData) => !!rowData.modelPlannedDate,
      editComponent: (props) => (
        <>
          <TextField
            id="date"
            type="date"
            InputLabelProps={{
              shrink: false,
            }}
          />
        </>
      ),
    },
    {
      title: "Modeller",
      field: "modellerName",
      //validate: (rowData) => !!rowData.modellerName,
    },
    {
      title: "Modeller Trained?",
      field: "modellerTrained",
      //validate: (rowData) => !!rowData.modellerTrained,
      //if value = Yes, then display Material UI icon Check, colour green
      //if value = No then display blank
      render: (rowData) => (
        <> {rowData.modellerTrained === "Yes" ? <CheckRoundedIcon /> : <></>}</>
      ),
      lookup: { Yes: "Yes", No: "No" },
    },
    {
      title: "Collaborator Set Up?",
      field: "collaboratorSetUp",
      //validate: (rowData) => !!rowData.modellerTrained,
      //if value = Yes, then display Material UI icon Check, colour green
      //if value = No then display blank
      render: (rowData) => (
        <>
          {rowData.collaboratorSetUp === "Yes" ? <CheckRoundedIcon /> : <></>}
        </>
      ),
      lookup: { Yes: "Yes", No: "No" },
    },
    {
      title: "Site Manager",
      field: "siteManagerName",
      validate: (rowData) => !!rowData.siteManagerName,
    },
    {
      title: "Equipment Sent?",
      field: "equipmentSent",
      validate: (rowData) => !!rowData.equipmentSent,
      //if value = Yes, then display Material UI icon Check, colour green
      //if value = No then display blank
      render: (rowData) => (
        <> {rowData.equipmentSent === "Yes" ? <CheckRoundedIcon /> : <></>}</>
      ),
      lookup: { Yes: "Yes", No: "No" },
    },
    {
      title: "Readiness Check",
      field: "ready",
      validate: (rowData) => !!rowData.ready,
      //if value = Yes, then display Material UI icon Check, colour green
      //if value = No then display blank
      render: (rowData) => (
        <> {rowData.ready === "Yes" ? <CheckRoundedIcon /> : <></>}</>
      ),
      lookup: { Yes: "Yes", No: "No" },
    },
    {
      title: "Exported?",
      field: "exported",
      validate: (rowData) => !!rowData.exported,
      //if value = Yes, then display Material UI icon Check, colour green
      //if value = No then display blank
      render: (rowData) => (
        <> {rowData.exported === "Yes" ? <CheckRoundedIcon /> : <></>}</>
      ),
      lookup: { Yes: "Yes", No: "No" },
    },
    { title: "Modified By", field: "lastUpdatedBy", editable: "never" },
    {
      title: "Modified On",
      field: "lastUpdatedOn",
      editable: "never",
      type: "date",
      dateSetting: { locale: "en-NZ", format: "dd/MM/yyyy" },
    },
  ];

  async function getSites() {
    await Api.get("sitelist").then((res) => {
      setSiteData(res?.data);
      setLoading(false);
    });
  }

  async function getSiteTechnical() {
    await Api.get("sitetechnical").then((res) => {
      setSiteList(res?.data);
    });
  }

  async function addSite(site) {
    await Api.post("sitemodel", site)
      .then((res) => {
        getSites();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function updateSite(site) {
    await Api.put("sitemodel", site)
      .then((res) => {
        getSites();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function deleteSite(id) {
    await Api.delete("sitemodel/" + id)
      .then((res) => {
        getSites();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function refreshSiteData(newSite) {
    // get updated Site Data
    getSiteTechnical();
    setShowSite(false);
    //reset dropdown
    setCurrSite(newSite);
  }

  useEffect(() => {
    setLoading(true);
    getSites();
    getSiteTechnical();
  }, [localStorage.getItem('accessToken')]);

  let bgcolor = isDarkMode ? "gray" : "white";

  return (
    <Box bgcolor={bgcolor} flex={5}>
      <ThemeProvider theme={defaultMaterialTheme}>
        <div style={{ width: "80vw", height: "90vh", padding: "20px" }}>
          <MaterialTable
            icons={tableIcons}
            columns={columns}
            data={siteData}
            isLoading={loading}
            title="Plant List"
            style={{
              backgroundColor: bgcolor,
              color: isDarkMode ? "white" : "black",
            }}
            options={{
              padding: "dense",
              addRowPosition: "first",
              //actionsColumnIndex:-1,
              pageSizeOptions: [10, 20, 30, 50, 100],
              pageSize: 10,
              maxBodyHeight: "80vh",
              // exportButton:true,
              exportButton: {
                csv: true,
                pdf: false,
              },
              // Styling
              // headerStyle: { background: '#8DC8E8', color: '#FFF' },
              headerStyle: {
                border: "2px solid gray",
                background: "#01579b",
                color: "#FFF",
              },
              cellStyle: {
                border: "2px solid lightgray",
                whiteSpace: "nowrap",
              },
              rowStyle: {
                backgroundColor: isDarkMode ? "gray" : "white",
                color: isDarkMode ? "white" : "black",
              },
              searchFieldStyle: {
                color: isDarkMode ? "white" : "black",
              },
            }}
            editable={{
              onRowAdd: (newSite) =>
                new Promise((resolve, reject) => {
                  if (!newSite.site || !newSite.description) {
                    reject();
                  } else {
                    //manually setting the site
                    newSite.site = currSite;
                    addSite(newSite);
                    setTimeout(() => resolve(), 500);
                  }
                }),
              onRowUpdate: (newSite, oldSite) =>
                new Promise((resolve, reject) => {
                  if (!newSite.site || !newSite.description) {
                    reject();
                  } else {
                    updateSite(newSite);
                    setTimeout(() => resolve(), 500);
                  }
                }),
              onRowDelete: (selectedSite) =>
                new Promise((resolve, reject) => {
                  deleteSite(selectedSite.id);
                  setTimeout(() => resolve(), 1000);
                }),
              onRowAddCancelled: () => setShowSite(false),
            }}
            localization={{
              body: {
                editRow: {
                  deleteText: (
                    <div style={{ color: isDarkMode ? "white" : "black" }}>
                      "Are you sure want to delete this site ?"
                    </div>
                  ),
                },
              },
            }}
            actions={[
              // {
              //   icon: () => (
              //     <IconButton aria-label="delete" size="large">
              //       <ChatBubbleOutlineIcon />
              //     </IconButton>
              //   ),
              //   isFreeAction: true,
              // },

              {
                icon: () => (
                  <IconButton aria-label="delete" size="large">
                    <ChatBubbleOutlineIcon />
                  </IconButton>
                ),
                tooltip: "Comment Section",
                // onClick: (rowData) => showComments(rowData),
                onClick: (event, rowData) => {
                  openDialog();
                  setCommentRowData(rowData);
                },
              },
            ]}
          />
        </div>
      </ThemeProvider>
      <div style={{ display: showSite ? "block" : "none" }}>
        <AddSite show={showSite} refreshSites={refreshSiteData} />
      </div>
      {open && (
        <CommentDialog
          open={open}
          onClose={closeDialog}
          commentInfo={commentRowData}
        />
      )}
    </Box>
  );
}

export default SiteList;
