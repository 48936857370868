import React, { useState, useEffect, forwardRef } from "react";
import Api from "../services/httpservice";
import MaterialTable from "material-table";
import { ThemeProvider, createTheme, Box, TextField } from "@mui/material";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

import PlacesAutocomplete from "./shared/PlacesAutocomplete";

function ManageSites() {
  const [siteData, setSiteData] = useState([]);
  const defaultMaterialTheme = createTheme();
  const isDarkMode = JSON.parse(localStorage.getItem("darkMode"));

  const [loading, setLoading] = useState(false)

  // const Transition = React.forwardRef(function Transition(props, ref) {
  //   return <Slide direction="up" ref={ref} {...props} />;
  // });

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const columns = [
    {
      title: "Site",
      field: "site",
      validate: (rowData) => !!rowData.site,
      editComponent: (props) => {
        return (
          <TextField
            error={!props.value}
            onBlur={(e) => {
              props.onRowDataChange({
                ...props.rowData,
                ["site"]: e.target.value,
              });
            }}
            onChange={(e) => {
              props.onRowDataChange({
                ...props.rowData,
                ["site"]: e.target.value,
              });
            }}
            value={props.value}
          />
        );
      },
    },
    {
      title: "Address",
      field: "address",
      validate: (rowData) => !!rowData.address,
      editComponent: (props) => {
        return <PlacesAutocomplete props={props} />;
      },
    },
    { title: "Latitude", field: "latitude", editable: "never" },
    { title: "Longitude", field: "longitude", editable: "never" },
    { title: "Modified By", field: "lastUpdatedBy", editable: "never" },
    {
      title: "Modified On",
      field: "lastUpdatedOn",
      editable: "never",
      type: "date",
      dateSetting: { locale: "en-NZ", format: "dd/MM/yyyy" },
    },
  ];

  async function getSites() {
    await Api.get("sitetechnical").then((res) => {
      setSiteData(res?.data);
      setLoading(false);
    });
  }

  async function addSite(site) {
    await Api.post("sitetechnical", site)
      .then((res) => {
        getSites();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function updateSite(site) {
    await Api.put("sitetechnical", site)
      .then((res) => {
        getSites();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function deleteSite(id) {
    await Api.delete("sitetechnical/" + id)
      .then((res) => {
        getSites();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    setLoading(true);
    getSites();
  }, [localStorage.getItem('accessToken')]);

  let bgcolor = isDarkMode ? "gray" : "white";
  return (
    <>
      <Box bgcolor={bgcolor} flex={5}>
        <ThemeProvider theme={defaultMaterialTheme}>
          <div style={{ width: "80vw", height: "90vh", padding: "20px" }}>
            <MaterialTable
              icons={tableIcons}
              columns={columns}
              data={siteData}
              isLoading={loading}
              title="Manage Sites"
              style={{
                backgroundColor: bgcolor,
                color: isDarkMode ? "white" : "black",
              }}
              options={{
                padding: "dense",
                addRowPosition: "first",
                //actionsColumnIndex:-1,
                pageSizeOptions: [10, 20, 30, 50, 100],
                pageSize: 10,
                maxBodyHeight: "80vh",
                // exportButton:true,
                exportButton: {
                  csv: true,
                  pdf: false,
                },
                // Styling
                // headerStyle: { background: '#8DC8E8', color: '#FFF' },
                headerStyle: {
                  border: "2px solid gray",
                  background: "#01579b",
                  color: "#FFF",
                },
                rowStyle: {
                  backgroundColor: isDarkMode ? "gray" : "white",
                  color: isDarkMode ? "white" : "black",
                },
                cellStyle: {
                  border: "2px solid lightgray",
                  whiteSpace: "nowrap",
                },
                searchFieldStyle: {
                  color: isDarkMode ? "white" : "black",
                },
              }}
              localization={{
                body: {
                  editRow: {
                    deleteText: (
                      <div style={{ color: isDarkMode ? "white" : "black" }}>
                        "Are you sure want to delete this site ? All site
                        related plant(s) information will be deleted."
                      </div>
                    ),
                  },
                },
              }}
              editable={{
                onRowAdd: (newSite) =>
                  new Promise((resolve, reject) => {
                    if (!newSite.site || !newSite.address) {
                      reject();
                    } else {
                      addSite(newSite);
                      setTimeout(() => resolve(), 500);
                    }
                  }),
                onRowUpdate: (newSite, oldSite) =>
                  new Promise((resolve, reject) => {
                    if (!newSite.site || !newSite.address) {
                      reject();
                    } else {
                      updateSite(newSite);
                      setTimeout(() => resolve(), 500);
                    }
                  }),
                onRowDelete: (selectedSite) =>
                  new Promise((resolve, reject) => {
                    deleteSite(selectedSite.id);
                    setTimeout(() => resolve(), 1000);
                  }),
                //onRowAddCancelled: () => setShowSite(false)
              }}
            />
          </div>
        </ThemeProvider>
      </Box>
    </>
  );
}

export default ManageSites;
