const request = (method, path, body) => {
    const token = localStorage.getItem('accessToken');
    const h = new Headers();
    h.append('content-type', 'application/json');
    h.append('Authorization', `Bearer ${token}`);
    
    const url = process.env.REACT_APP_API_BASE_URL + "/api/" + path;    
    const options = { method, headers: h };

    if (body) {
        options.body = JSON.stringify(body);
    }
        
    return fetch(url, options);
};


const Api = {
    get(path) {
        return request('GET', path)
        .then(_ => _.json())        
        .catch(e => console.error(e.message));
    },
    post(path, data = []) {
        return request('POST', path, data);
    },
    put(path, data = []) {
        return request('PUT', path, data);
    },
    delete(path, data = []) {
        return request('DELETE', path, data);
    },
};

export default Api;