import { useState, createContext } from 'react';

//These are global variables
export const AppRunTimeContext = createContext({
    g_userRoles: [],
    g_user: []
});

export const ContextProvider = (props) => {
    const [g_userRoles, g_setUserRoles] = useState([]); //Contains Auth Roles for logged in user
    const [g_user, g_setUser] = useState(false); //Flag to tell if user details are fetched from API

    return (
        <AppRunTimeContext.Provider
            value={{
                g_userRoles: g_userRoles,
                g_user: g_user,
                g_setUserRoles: g_setUserRoles,
                g_setUser: g_setUser
            }}
        >
            {props.children}
        </AppRunTimeContext.Provider>
    );
};
