import React, { useState, useEffect, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import { Typography } from "@mui/material";
import { Grid, Button, TextField } from "@mui/material";
import { styled } from "@mui/styles";
import Divider from "@mui/material/Divider";
import Api from "../../services/httpservice";
import { format } from "date-fns";

const StyledLabel = styled(Typography)(() => ({
  color: "text.primary",
  fontWeight: 600,
  fontSize: "14px",
  padding: "3px",
}));

const CommentDialog = ({ open, onClose, commentInfo }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const initialFormValues = {
    comment: "",
    referenceId: commentInfo.id,
    referenceEntity: "sitemodels",
    parentReferenceId: "",
    parentReferenceEntity: "sitetechnical",
  };

  const [addComment, setAddComment] = useState(initialFormValues);
  const [commentsData, setCommentsData] = useState([]);

  const [errors, setErrors] = useState("");

  useEffect(() => {
    getComments();
  }, []);

  async function getComments() {
    await Api.get("sitemodelcomments/" + commentInfo.id).then((res) => {
      setCommentsData(res?.data);
    });
  }

  async function handleSave() {
    validate();
    if (addComment?.comment.length > 0) {
      await Api.post("sitemodelcomments", addComment)
        .then((res) => {
          onClose();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  const renderComment = () => {
    return commentsData?.map((row, key) => {
      return (
        <>
          <Grid key={key} container spacing={1} sx={{ mb: "4px" }}>
            <Grid item xs={8}>
              <StyledLabel component="span">{row.createdBy}</StyledLabel>
            </Grid>
            <Grid item xs={4}>
              <StyledLabel component="span">
                {format(new Date(row.createdOn), "dd/MM/yyyy H:mma")}
              </StyledLabel>
            </Grid>
            <Grid item xs={12} sx={{ maringBottom: "10px", maringTop: "10px" }}>
              <Divider
                sx={{ borderBottom: "1px solid", color: "#000" }}
                component="div"
                role="presentation"
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: "20px" }}>
              {row.comment}
            </Grid>
          </Grid>
        </>
      );
    });
  };

  function validate() {
    setErrors(addComment?.comment.length > 0 ? "" : "Comments cannot be empty");
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      sx={{ p: 2 }}
    >
      <DialogTitle>
        Comments
        {!!onClose && (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            mt: 1,
            ml: 0,
            mb: 3,
            width: "-webkit-fill-available",
            overflow: "hidden",
            overflowY: "scroll",
            height: "30vh",
          }}
        >
          <CardContent
            style={{
              paddingLeft: "0px",
              paddingBottom: "20px",
            }}
          >
            {commentsData?.length ? renderComment() : "No Comments posted"}
          </CardContent>
        </Box>
        <Grid container>
          <Grid item xs={12} />
        </Grid>
        <Grid container>
          <Grid item xs={12} />
          <TextField
            size="small"
            //error={commentError}
            fullWidth={true}
            label="Add Comment"
            value={addComment?.comment}
            multiline
            onChange={(e) => {
              validate();
              setAddComment({ ...addComment, comment: e.target.value });
            }}
            InputLabelProps={{
              shrink: true,
            }}
            rows={4}
            {...(errors.length > 0 && {
              error: true,
              helperText: errors,
            })}
          />
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleSave} variant="contained" size="small">
          Add
        </Button>
        <Button autoFocus onClick={onClose} variant="outlined" size="small">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CommentDialog;
