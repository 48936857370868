import React from "react";
import {
  AppBar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Toolbar,
  Typography,
  Switch,
} from "@mui/material";
import { useState } from "react";

import { Link } from "react-router-dom";
import FonterraLogo from "../../assets/fonterra-logo.png";
import ManufactureSiteLogo from "../../assets/ManufactureSite.png";

// Cutomize toolbar style

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
});

// const SearchBox = styled("div")(({ theme }) => ({
//   backgroundColor: "white",
//   padding:"0 10px",
//   borderRadius: theme.shape.borderRadius,
//   width: "40%"
// }))

const Icons = styled(Box)(({ theme }) => ({
  // backgroundColor: "white"
  display: "none",
  alignItems: "center",
  gap: "20px",
  [theme.breakpoints.up("sm")]: { display: "flex" },
}));

const UserProfile = styled(Box)(({ theme }) => ({
  // backgroundColor: "white"
  display: "flex",
  alignItems: "center",
  gap: "10px",
  [theme.breakpoints.up("sm")]: { display: "none" },
}));

const Navbar = ({ changeTheme }) => {
  const [open, setOpen] = useState(false);
  const isDarkMode = JSON.parse(localStorage.getItem("darkMode"));
  // let bgcolor = isDarkMode === true ? "#232329" : "#002D4E";
  return (
    <AppBar position="sticky" style={{ background: "#002D4E" }}>
      <div className="theme-button">
        <Switch
          checked={isDarkMode}
          onChange={() => {
            changeTheme(!isDarkMode);
          }}
          inputProps={{ "aria-label": "controlled" }}
          label="Dark Mode"
        />
        <span style={{ color: "lightgray" }}>Dark Mode</span>
      </div>
      <StyledToolbar>
        <Link to={"/"} style={{ textDecoration: "none", color: "white" }}>
          <IconButton
            variant="h6"
            size="small"
            aria-label="fonterra-logo-main"
            // sx={{ display: {xs: "block", sm: "none"} }}
            color="inherit"
          >
            <img
              src={FonterraLogo}
              loading="lazy"
              alt="fonterra logo"
              style={{ width: 100, height: 80 }}
            />
          </IconButton>
        </Link>

        <Link to={"/"} style={{ textDecoration: "none", color: "white" }}>
          <IconButton
            aria-label="manufacture-site-main"
            // sx={{ display: {xs: "block", sm: "none"} }}
            color="inherit"
          >
            <img
              src={ManufactureSiteLogo}
              alt="fonterra site logo"
              loading="lazy"
              style={{ width: 100, height: 80 }}
            />
            <Typography className="title-style">Matterport 3D Visualization</Typography>
          </IconButton>
        </Link>
        <Icons>
          {/* <Badge badgeContent={4} color="error">
              <MailIcon />
        </Badge>
        <Badge badgeContent={2} color="error">
              <Notifications />
        </Badge> */}
          {/* <Avatar sx={{ width:30, height:30 }} alt="Remy Sharp" src="/Ironman_logo.png" 
          onClick={e => setOpen(true)}
        /> */}
        </Icons>
        <UserProfile onClick={(e) => setOpen(true)}>
          {/* <Avatar sx={{ width:30, height:30 }} alt="Remy Sharp" src="/Ironman_logo.png" />
        <Typography> Tony Stark </Typography> */}
        </UserProfile>
      </StyledToolbar>

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        open={open}
        onClose={(e) => setOpen(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem>Profile</MenuItem>
        <MenuItem>My account</MenuItem>
        <MenuItem>Logout</MenuItem>
      </Menu>
    </AppBar>
  );
};

export default Navbar;
