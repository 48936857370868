import React, { useEffect, useRef, useState } from "react";
import {
  useJsApiLoader,
  GoogleMap,
  InfoWindow,
  Marker,
} from "@react-google-maps/api";
import mapStyles from "../styles/mapStyles";
import { Box, Switch } from "@mui/material";
import Api from "../services/httpservice";

import ModelUnavailable from "../assets/ModelUnavailable.png";
import ModelAvailable from "../assets/ModelAvailable.png";
import { useLinkClickHandler } from "react-router-dom";
import { Card } from "@mui/material";
import { Button, makeStyles } from '@material-ui/core';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import StarRateIcon from '@mui/icons-material/StarRate';

const MapView = ({ 
  onToggleLeftMapChange, 
  onToggleRightMapChange, 
  onZoomLevelLeft, 
  onZoomLevelRight, 
  isLeftToggled, 
  isRightToggled}) => { 
  // North new zealand - oban default to center the map frame
  const centerLeftLat = parseFloat(-37.477836);
  const centerLeftLng = parseFloat(175.702364);
  const defaultLeftZoom = 8;
  const NORTH_NEW_ZEALAND_BOUNDS = {
    north: 12.5009,
    south: -66.6307,
    west: 66.4776,
    east: 64.3256, 
  };

// South new zealand - oban default to center the map frame
  const centerRightLat = parseFloat(-41.398661);
  const centerRightLng = parseFloat(173.054936);
  const defaultRightZoom = 6;
  const SOUTH_NEW_ZEALAND_BOUNDS = {    
    north: -42.7000,
    south: -45.6307,
    west: 170.4776,
    east: 174.3256,  
  };

  const containerStyle = {
    width: "100%",
    height: "95vh",
    position: "auto",    
  };

  const isDarkMode = JSON.parse(localStorage.getItem("darkMode"));
  let map_style =
    isDarkMode === true ? mapStyles["darktheme"] : mapStyles["defaulttheme"];

  const [factoryWithModels, setFactoryWithModels] = useState([]);

  async function getSites() {
    await Api.get("sitemodel").then((res) => {     
      res ? setFactoryWithModels(res.data) : setFactoryWithModels([]);
    });
  }

   useEffect(() => {
    getSites();   
  }, [localStorage.getItem("accessToken")]);

  const [leftCollapsed, setLeftCollapsed] = useState(false);
  const [rightCollapsed, setRightCollapsed] = useState(false);
  
  const toggleLeft = () => {
    setLeftCollapsed(!leftCollapsed);
    onToggleLeftMapChange(!leftCollapsed);
  };
  
  const toggleRight = () => {    
    setRightCollapsed(!rightCollapsed);
    onToggleRightMapChange(!rightCollapsed);
  };

  function handleLeftZoomChanged(){  
    const zoomLevelLeft = this.getZoom();
    onZoomLevelLeft(zoomLevelLeft);
    setRightCollapsed(isRightToggled);      
  }

  function handleRightZoomChanged(){
    const zoomLevelRight = this.getZoom();
    onZoomLevelRight(zoomLevelRight);
    setLeftCollapsed(isLeftToggled);     
  }

  function LeftMarkerWithInfoWindow(props) {
    const [openLeft, setOpenLeft] = useState(false);

    function handleMarkerLeftOpen() {
      setOpenLeft(!openLeft);
    }
    return (
      <Marker       
        onClick={handleMarkerLeftOpen}
        position={props.position}       
        icon={{
          url: props.siteData.sitemodels.every(
            (plant) => plant.modelId === null || plant.modelId === ""
          ) // show unavailable icon if no plants under the site
            ? ModelUnavailable
            : ModelAvailable,
          scaledSize: new window.google.maps.Size(10, 10),
          origin: new window.google.maps.Point(0, 0),
          labelOrigin: new window.google.maps.Point(0, -10),
          anchor: new window.google.maps.Point(0, 0),
        }}
        title={props.siteData.site}
        label={{
          text: props.siteData.site,
          color: isDarkMode ? "white" : "#002D4E",          
          fontWeight: "bold",
        }}
      >
        {openLeft && (
          <InfoWindow
            options={{
              disableAutoPan: true,
              pixelOffset: new window.google.maps.Size(5, 0),
            }}
          >
            <div className="p-5">
              <span className="site-info">
                {props.siteData.site}
                <br />
              </span>

              {props.siteData?.sitemodels.map((model) =>
                model.modelId ? (
                  <>
                    <a
                      className="model-url"
                      href={
                        process.env.REACT_APP_MATTERPORT_URL + model.modelId
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {model.description}
                    </a>
                    <br />
                  </>
                ) : (
                  <></>
                )
              )}
            </div>
          </InfoWindow>
        )}
      </Marker>
    );
  }


  function RightMarkerWithInfoWindow(props) {
    const [openRight, setOpenRight] = useState(false);

    function handleMarkerRightOpen() {
      setOpenRight(!openRight);
    } 
    return (
      <Marker       
        onClick={handleMarkerRightOpen}
        position={props.position}
        icon={{
          url: props.siteData.sitemodels.every(
            (plant) => plant.modelId === null || plant.modelId === ""
          ) // show unavailable icon if no plants under the site
            ? ModelUnavailable
            : ModelAvailable,
          scaledSize: new window.google.maps.Size(10, 10),
          origin: new window.google.maps.Point(0, 0),
          labelOrigin: new window.google.maps.Point(0, -10),
          anchor: new window.google.maps.Point(0, 0),        
        
        }}
        title={props.siteData.site}
        label={{
          text: props.siteData.site,
          color: isDarkMode ? "white" : "#002D4E",   
          fontSize: '11px',
          fontWeight: "bold",
        }}
      >
        {openRight && (
          <InfoWindow
            options={{
              disableAutoPan: true,
              pixelOffset: new window.google.maps.Size(5, 0),
            }}
          >
            <div className="p-5">
              <span className="site-info">
                {props.siteData.site}
                <br />
              </span>

              {props.siteData?.sitemodels.map((model) =>
                model.modelId ? (
                  <>
                    <a
                      className="model-url"
                      href={
                        process.env.REACT_APP_MATTERPORT_URL + model.modelId
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {model.description}
                    </a>
                    <br />
                  </>
                ) : (
                  <></>
                )
              )}
            </div>
          </InfoWindow>
        )}
      </Marker>
    );
  }
  return (
    factoryWithModels && (
        <>
         <div style={{ display:  'flex', flexDirection: 'row', width: '100%', margin: '15px auto', flexWrap: 'wrap' }}>  
            <div style={{ display:  'flex', flexDirection: 'row', width: '100%' }}>     
            <Button onClick={toggleLeft} 
              style={{
                minWidth:'32px', 
                width: '32px', 
                backgroundColor:leftCollapsed ? '#ededed': 'rgba(25, 118, 210, 0.08)', 
                borderRadius:'0px' 
              }}>
              {leftCollapsed? <KeyboardDoubleArrowRightIcon 
                style={{fontSize: '1.8rem', color:'#aeaeae'}} 
                />: <KeyboardDoubleArrowLeftIcon 
                style={{fontSize: '1.8rem', color:'#0000ff'}} 
              />}            
            </Button>
            <Card variant="outlined" 
              style={{ 
                width: leftCollapsed ? '0' : '95%', 
                float: 'left', 
                overflow: 'hidden', 
                transition: 'width 0.3s', 
                backgroundColor: '#f0f0f0', 
                height: '80vh', 
                borderRadius:'0px' 
              }}>
              <Box bgcolor="white" flex={8} style={{zoom:"84.5%"}}>
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  onZoomChanged={handleLeftZoomChanged} 
                  options={{
                    styles: map_style,
                    disableDefaultUI: true,
                    disableDoubleClickZoom: true,
                    scrollwheel: true,              
                    zoom: defaultLeftZoom,
                    center: { lat: centerLeftLat, lng: centerLeftLng },
                    gestureHandling: "cooperative",
                    minZoom: 5,
                    maxZoom: 18,
                    restriction: {
                      latLngBounds: NORTH_NEW_ZEALAND_BOUNDS,
                      strictBounds: false,               
                    },
                    country: 'es',
                    disableAutoPan: true,              
                  }}
                >                      
                  {/* <Marker position={position} /> */}
                  {factoryWithModels?.map((site) => (
                    <LeftMarkerWithInfoWindow
                      key={site.id}
                      siteData={site}
                      isOpen={true}
                      position={
                        new window.google.maps.LatLng(site.latitude, site.longitude)
                      }
                      />
                    ))}
                  </GoogleMap>
                  </Box>  
                
                </Card>    
            
            <Button onClick={toggleRight} 
              style={{
                minWidth:'32px', 
                width: '32px', 
                backgroundColor:leftCollapsed ? '#ededed': 'rgba(25, 118, 210, 0.08)', 
                borderRadius:'0px' 
              }}>
              {rightCollapsed? <KeyboardDoubleArrowRightIcon 
                style={{fontSize: '1.8rem', color:'#aeaeae'}} 
                />: 
                <KeyboardDoubleArrowLeftIcon 
                style={{fontSize: '1.8rem', color:'#0000ff'}} 
                />}            
            </Button>          
            <Card variant="outlined" 
              style={{ width: rightCollapsed ? '0' : '95%', 
              float: 'left', 
              overflow: 'hidden', 
              transition: 'width 0.3s', 
              backgroundColor: '#e0e0e0', 
              height: '80vh', 
              borderRadius:'0px' 
            }}>
              <Box bgcolor="white" flex={6} style={{zoom:"105%"}} >
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  onZoomChanged={handleRightZoomChanged}
                  options={{
                    styles: map_style,             
                    disableDefaultUI: true,
                    disableDoubleClickZoom: true,
                    scrollwheel: true,
                    zoom: defaultRightZoom,
                    center: { lat: centerRightLat, lng: centerRightLng },
                    gestureHandling: "cooperative",
                    minZoom: 5,
                    maxZoom: 18,
                    center: { lat: (SOUTH_NEW_ZEALAND_BOUNDS.north + SOUTH_NEW_ZEALAND_BOUNDS.south) / 2, lng: (SOUTH_NEW_ZEALAND_BOUNDS.east + SOUTH_NEW_ZEALAND_BOUNDS.west) / 2 },
                    restriction: {
                      latLngBounds: NORTH_NEW_ZEALAND_BOUNDS,
                      strictBounds: false,
                    },
                    disableAutoPan: true,
                  }}
                >
                  {/* <Marker position={position} /> */}
                  {factoryWithModels?.map((site) => (                         
                    <RightMarkerWithInfoWindow
                      key={site.id}
                      siteData={site}
                      isOpen={true}
                      position={
                        new window.google.maps.LatLng(site.latitude, site.longitude)
                      }
                    />
                  ))}
                </GoogleMap>
              </Box>
            </Card>
            </div>           
           {(leftCollapsed && rightCollapsed) ? '': (<Box bgcolor="white" 
              style={{
                width: '100%',
                display:'flex', 
                justifyContent: 'flex-start', 
                padding: '6px 0px 0px 5px', 
                marginLeft: '24px',
                color:'#008000',                
                fontSize:'12px', 
                fontWeight:'bold'}}>
                   <StarRateIcon style={{fontSize: '0.9rem'}} />
                Zoom In / Zoom Out for detailed Views
            </Box>)}
          </div>  
        </>
     )
  );
}
export default MapView;