import MapView from "./components/MapView";
import Sidebar from "./components/home/Sidebar";
import { Box, Stack } from "@mui/material";
import Navbar from "./components/home/Navbar";
import { Routes, Route, Navigate } from "react-router-dom";
import SiteList from "./components/SiteList";
import { useEffect, useState, useContext } from "react";
import ManageSites from "./components/ManageSites";
import {
  MsalAuthenticationTemplate,
  useMsal,
  useAccount,
} from "@azure/msal-react";
import {
  InteractionRequiredAuthError,
  InteractionType,
} from "@azure/msal-browser";
import { loginRequest } from "./authConfig";
import { AppRunTimeContext } from './Context';
import { useJsApiLoader } from "@react-google-maps/api";

export default function App() {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [user, setUser] = useState(null);
  const { g_setUser } = useContext(AppRunTimeContext);
  const [darkMode, setDarkMode] = useState(false); 
  const [loadMap, setLoadMap] = useState(false);
  const loadGoogleMapScript = (callback) => {
    if (
      typeof window.google === "object" &&
      typeof window.google.maps === "object"
    ) {
      callback();
    } else {
      const googleMapScript = document.createElement("script");
      googleMapScript.src =
        "https://maps.googleapis.com/maps/api/js?key=" +
        process.env.REACT_APP_GOOGLE_MAPS_API_KEY +
        "&libraries=places";
      window.document.body.appendChild(googleMapScript);
      googleMapScript.addEventListener("load", callback);
    }
  };

  useEffect(() => {
    localStorage.setItem("darkMode", false); 
    loadGoogleMapScript(() => {
      setLoadMap(true);
    });
  }, []);

  useEffect(async () => {
    if (account && inProgress === "none" && !user) {
      let MSADResponseData = {};
      try {
        MSADResponseData = await instance.acquireTokenSilent({
          scopes: loginRequest.scopes,
          account: account,
        });
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          if (account && inProgress === "none") {
            MSADResponseData = await instance.acquireTokenPopup({
              scopes: loginRequest.scopes,
            });
          }
        }
      }
      setUser(MSADResponseData);
      g_setUser(MSADResponseData);
      localStorage.setItem("accessToken", MSADResponseData.accessToken);
      localStorage.setItem("ACCROLES", MSADResponseData.idTokenClaims.roles);
    }
  }, [account, inProgress, instance]);

 const changeTheme = () => {
    setDarkMode(!darkMode)
    localStorage.setItem("darkMode", !darkMode);
  };

  /* SideBar toggle fns*/
  const [isSideToggled, setIsSideToggled] = useState(false);   
  const handleSidebarChange = (toggleValue) => {
    setIsSideToggled(toggleValue);
  };

  /* LeftMap toggle fns*/
  const [isLeftToggled, setIsLeftToggled] = useState(false);   
  const handleLeftMapChange = (toggleLeftMapValue) => {   
    setIsLeftToggled(toggleLeftMapValue);
    console.log('Left:',toggleLeftMapValue);
  }
  
  /* RightMap toggle fns*/
  const [isRightToggled, setIsRightToggled] = useState(false); 
  const handleRightMapChange = (toggleRightMapValue) => {
    setIsRightToggled(toggleRightMapValue);
  }

  const handleZoomLeftChange = (zoomLevelLeftValue) => {    
    if (zoomLevelLeftValue >= 9) {    
      setIsRightToggled(true);
    }        
  }

  const handleZoomRightChange = (zoomLevelRightValue) => {
    if (zoomLevelRightValue >= 7) {
      setIsLeftToggled(true);
    } 
  }


  return loadMap && (
    <MsalAuthenticationTemplate
      authenticationRequest={loginRequest}
      interactionType={InteractionType.Redirect}
    >
      <Box>
          <Navbar changeTheme={changeTheme}/>
          <Stack direction="row" justifyContent="space-between">
                <Sidebar onToggleSidebarChange={handleSidebarChange} />
                <Routes>
                  {/* <Route exact path="/" element={<Home />}></Route> */}
                  <Route exact path="/" element={<Navigate to="/maps" />}></Route>
                  <Route path="/maps" element={<MapView  
                    onToggleLeftMapChange={handleLeftMapChange} 
                    onToggleRightMapChange={handleRightMapChange} 
                    onZoomLevelLeft={handleZoomLeftChange} 
                    onZoomLevelRight={handleZoomRightChange} 
                    isLeftToggled ={isLeftToggled} 
                    isRightToggled ={isRightToggled}
                  />}></Route>
                  <Route path="/sitelist" element={<SiteList />}></Route>
                  <Route path="/managesites" element={<ManageSites />}></Route>
                </Routes>
          </Stack>
      </Box>
    </MsalAuthenticationTemplate>
  );
}
