import React from "react";
import { Box, List, Divider, Button } from "@mui/material";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

import EditLocationAltTwoToneIcon from "@mui/icons-material/EditLocationAltTwoTone";
import ReorderTwoToneIcon from "@mui/icons-material/ReorderTwoTone";

import { Link, useLocation } from "react-router-dom";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { ListItem } from "@material-ui/core";
import { useState } from "react";

const Sidebar = ({ onToggleSidebarChange }) => {  
  const isDarkMode = JSON.parse(localStorage.getItem("darkMode"));
  let bgcolor = isDarkMode === true ? "#232329" : "background.paper";

  const routes = [
    {
      id: 1,
      route: "/maps",
      icon: <EditLocationAltTwoToneIcon />,
      displayName: "Map View",    
    },
    {
      id: 2,
      route: "/sitelist",
      icon: <ReorderTwoToneIcon />,
      displayName: "List View",
    },
    {
      id: 3,
      route: "/managesites",
      icon: <ReorderTwoToneIcon />,
      displayName: "Manage Sites",
    },
  ];

  const [sideopen, setSideopen] = useState(false)
  const toggleOpen = () => {       
      setSideopen(!sideopen)
      onToggleSidebarChange(!sideopen);
  }

  const { pathname } = useLocation();

  return (
    <Box
      sx={{ display: { xs: "none", sm: "block" }, bgcolor: bgcolor }}
      p={1}
      style={{ width: sideopen ? '52px' : '220px'}}     
    >     
      <Button onClick={toggleOpen} 
        style={{
          marginTop:'7px', 
          backgroundColor: sideopen ? '#f0f0f0':'', 
          minWidth: '60px', 
          borderRadius:'0px'
        }}>
      {sideopen? <KeyboardDoubleArrowRightIcon 
        style={{
          fontSize: '1.8rem', 
          marginLeft: '-8px', 
          color: '#000'}} 
        />: <KeyboardDoubleArrowLeftIcon 
        style={{
          fontSize: '1.8rem',
          marginLeft: '-8px', 
          color: '#000'}} 
        />}            
      </Button>
      <List>
        {routes.map((item) => {
          return (
            <Link
              to={item.route}
              key={item.route}
              style={{
                textDecoration: "none",
                color: isDarkMode ? "white" : "black",
              }}
            >
              <ListItemButton selected={item.route === pathname} 
                style={{
                  width:'100%',
                  display: 'flex', 
                  justifyContent: sideopen ? 'flex-start' : '', 
                  padding: sideopen ? '12px 16px' : '8px 16px'
                }}>            
                <ListItemIcon style={{ color: isDarkMode ? "white" : "black",minWidth:'56px' }}>
                  {item.icon}
                </ListItemIcon>
                 <ListItemText primary={item.displayName} style={{display: sideopen ? 'none' : 'block'}}/>                
              </ListItemButton>
            </Link>
          );
        })}
      </List>

      <Divider />
    </Box>
  );
};

export default Sidebar;
