import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { loginRequest } from "./authConfig";
import { ContextProvider } from "./Context/index";
import { MsalProvider } from "@azure/msal-react";

import { BrowserRouter } from "react-router-dom";

import "./styles/global.css";

const msalInstance = new PublicClientApplication(msalConfig);
const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account)
    msalInstance.setActiveAccount(event.payload.account);

  if (event.eventType === EventType.LOGIN_FAILURE)
    console.log(JSON.stringify(event));
});

const acquireAccessToken = async (msalInstance) => {
  const activeAccount = msalInstance.getActiveAccount();

  if (!activeAccount && accounts.length === 0) {
    alert("Please login with your Fonterra Domain. Refreshing Page");
    window.location.reload();
  }

  const authResult = await msalInstance.acquireTokenSilent({
    scopes: loginRequest.scopes,
    account: activeAccount || accounts[0],
  });

  return authResult.accessToken;
};

ReactDOM.render(
  <BrowserRouter basename="/">
    <MsalProvider instance={msalInstance}>
      <React.StrictMode>
        <ContextProvider>
          <App />
        </ContextProvider>
      </React.StrictMode>
    </MsalProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
